import type { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'

export const RecentSearchQueries: QueryResolvers = {
  async recentSearchQueries(parent, args, ctx) {
    const resp = await ctx.services.recentSearch.getRecentSearchQueries({
      regionId: args.data.regionId.toString(),
      referrer: {
        experiment: ctx.experiment,
      },
    })

    if (!resp) {
      throw new Error('Failed to recent search result')
    }

    return {
      queries: resp.queries,
      experiment: resp.experiment,
      error: resp.error,
    }
  },
}
